
import EyuVerfication from "@/components/auth/VerficationComponent.vue";
import EyuImageViewer from "@/components/layout/ImageViewerComponent.vue";
import EyuAlertHandler from "@/components/layout/AlertHandlerComponent.vue";
export default {
  name: "App",
  components: {
    EyuVerfication,
    EyuAlertHandler,
    EyuImageViewer,
  },
};
