import axios from 'axios';
import { Response, StreamCriteria } from '../_data';
import { Stream } from '../_data/models/stream';

export const streamsService = {
    createStream,
    showStream,
    joinStream,
    joinStreamVisitor,
    updateStream,
    deleteStream,
    getStreamRecords
}

function createStream(data: StreamCriteria): Promise<Response<Stream>> {
    const url = "user/chat/streams";
    return axios
        .post<Stream>(url, data);
}


function showStream(streamId: string): Promise<Response<Stream>> {
    const url = "user/chat/streams/" + streamId;
    return axios
        .get<Stream>(url);
}
function getStreamRecords(streamId: string): Promise<Response<Stream>> {
    const url = "user/chat/streams/" + streamId+"/recordings";
    return axios
        .get<Stream>(url);
}


function joinStream(streamId: string, passcode?: string): Promise<Response<Stream>> {
    const url = `user/chat/streams/${streamId}/join`;
    return axios
        .post<Stream>(url, { passcode });
}
function joinStreamVisitor(streamId: string, passcode?: string): Promise<Response<Stream>> {
    const url = `user/chat/streams/${streamId}/visitor/join`;
    return axios
        .post<Stream>(url, { passcode });
}



function updateStream(streamId: string, data: StreamCriteria): Promise<Response<Stream>> {
    const url = `user/chat/streams/${streamId}?_method=PATCH`;
    return axios
        .post<Stream>(url, data);
}

function deleteStream(streamId: string): Promise<Response<void>> {
    const url = `user/chat/streams/${streamId}?_method=DELETE`;
    return axios
        .post<void>(url);
}
