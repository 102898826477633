
import { mapActions, mapState } from "vuex";
export default {
  name: "EyuVerfication",
  data() {
    return {
      code: null,
      verefied: false,
    };
  },
  computed: {
    ...mapState("account", ["user"]),
  },
  methods: {
    ...mapActions("account", ["getVerificationCode", "verifyPhone"]),
    verify(): void {
      this.verifyPhone(this.code).then(() => {
        const elem = this.$refs.close;
        elem.click();
        this.verefied = true;
      });
    },
  },
};
