const facebookAppId = process.env.VUE_APP_FACEBOOK_APP_ID;

export function initFacebookSdk() {
    return new Promise(resolve => {
        // wait for facebook sdk to initialize before starting the vue app
        window.fbAsyncInit = function () {
            FB.init({
                appId: facebookAppId,
                cookie: true,
                xfbml: true,
                version: 'v8.0'
            });

            // auto authenticate with the api if already logged in with facebook
            FB.getLoginStatus(({ authResponse }) => {
                // if (authResponse && authResponse.accessToken) {
                // userService.facebookInitLogin(authResponse.accessToken).then(resolve);
                // } else {
                resolve();
                // }
            });
        };

        // load facebook sdk script
        (function (d, s, id) {
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    });
}

export async function fbLogin() {
    // login with facebook then authenticate with the API to get a JWT auth token
    await FB.logout();

    let authResponse;

    await FB.login(function (response) {
        authResponse = response;
    }, {
        scope: 'email',
        return_scopes: true
    });

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(authResponse);
        }, 5000);
    });

}