import { Dispatch } from 'vuex';
import { Conversation, ConversationOperationsEnum, Message, Recording, Response } from '../_data';
import { chatService } from '../_services';

const actions = {
    createConversation(_,
        { title, description, image, participants }: { title: string, description: string, image: string, participants: string[] }
    ): Promise<Conversation> {
        const d = { title, description, image, participants };
        return chatService.createConversation(d)
            .then(
                res => {
                    return res.data;
                }
            );
    },
    getConversations(_, page): Promise<Response<Conversation[]>> {
        return chatService.getConversations(page)
            .then(
                res => {
                    return res;
                }
            );
    },
    getConversation(_, conversationId): Promise<Conversation> {
        return chatService.getConversation(conversationId)
            .then(
                res => {
                    return res.data;
                }
            );
    },
    getConversationsMessages(_, { conversationId, page, filter }: { conversationId: string, page: number, filter: string }): Promise<Response<Message[]>> {
        return chatService.getConversationsMessages(conversationId, page, filter)
            .then(
                res => {
                    return res;
                }
            );
    },
    seen(_, { conversationId, messageId, everyone }: { conversationId: string, messageId: string, everyone: 0 | 1 }): Promise<Response<Message[]>> {
        return chatService.seen(conversationId, messageId, everyone)
            .then(
                res => {
                    return res;
                }
            );
    },
    message(_, { conversationId, formData }: { conversationId: string, formData: object }): Promise<Message> {
        return chatService.message(conversationId, formData)
            .then(
                res => {
                    return res.data;
                }
            );
    },

    reply(_, { conversationId, messageId, type, body }: { conversationId: string, messageId: string, type: string, body: string }): Promise<Message> {
        return chatService.reply(conversationId, messageId, type, body)
            .then(
                res => {
                    return res.data;
                }
            );
    },
    operateOnConversation({ dispatch }: { dispatch: Dispatch; }, { conversationId, operation, body, msg }: { conversationId: string, operation: ConversationOperationsEnum, body: object, msg?: string }) {
        return chatService.operateOnConversation(conversationId, operation, body)
            .then(
                res => {
                    const op = msg || (operation + ' successful');
                    dispatch('alert/success', op, { root: true });
                    return res.data;
                }
            );
    },

    streamsFilter(_, { date, type }: { date: string, type: 'day' | 'month' }): Promise<Message> {
        return chatService.streamsFilter(date, type)
            .then(
                res => {
                    return res.data;
                }
            );
    },
    deleteConverstation(_, conversationId): Promise<void> {
        return chatService.deleteConverstation(conversationId).then(
            res => { return res.data }
        );
    },
    getConversationRecordings(_, { conversationId, page }: { conversationId: string, page: number }): Promise<Response<Recording[]>> {
        return chatService.getConversationRecordings(conversationId, page).then(
            res => { return res }
        );
    },


}

export const chat = {
    namespaced: true,
    actions,
};