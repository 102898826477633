import { Commit } from 'vuex';

export interface AlertState {
    type: string;
    message: string;
}

const state: AlertState = {
    type: null,
    message: null,
};

const getters = {
    alert: (state: AlertState) => {
        return state
    },
}

const actions = {
    success({ commit }: { commit: Commit; }, message: string) {
        commit('success', message);
    },
    error({ commit }: { commit: Commit; }, message: string) {
        commit('error', message);
    },
    clear({ commit }: { commit: Commit; }) {
        commit('clear');
    },
};

const mutations = {
    success(state: AlertState, message: string) {
        state.type = 'alert-success';
        state.message = message;
    },
    error(state: AlertState, message: string) {
        state.type = 'alert-danger';
        state.message = message;
    },
    clear(state: AlertState) {
        state.type = null;
        state.message = null;
    },
};

export const alert = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
