import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.alert.message)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: "alert-msg",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearAlert())),
        class: _normalizeClass(`pointer-cursor text-capitalize sticky-top text-center mb-0 alert ${_ctx.alert.type}`),
        innerHTML: _ctx.alert.message
      }, null, 10, _hoisted_1))
    : _createCommentVNode("", true)
}