import axios from 'axios';
import { Conversation, ConversationOperationsEnum, Message, Recording, Response } from '../_data';

export const chatService = {
    createConversation,
    getConversations,
    getConversation,
    getConversationsMessages,
    deleteConverstation,
    seen,
    message,
    reply,
    operateOnConversation,
    streamsFilter,
    getConversationRecordings
}

function createConversation(data): Promise<Response<Conversation>> {
    const url = `user/chat/conversations`;
    return axios.post<Conversation[]>(url, data);
}
function getConversations(page): Promise<Response<Conversation[]>> {
    const url = `user/chat/conversations?page=${page}`;
    return axios.get<Conversation[]>(url);
}

function getConversation(conversationId: string): Promise<Response<Conversation>> {
    const url = `user/chat/conversations/${conversationId}`;
    return axios.get<Conversation[]>(url);
}

function getConversationsMessages(conversationId: string, page: number, filter?: string): Promise<Response<Message[]>> {
    let url = `user/chat/conversations/${conversationId}/messages?sort=-created_at&include=sender&page=${page}`;
    if (filter) { url += '&filter[type]=' + filter }
    return axios.get<Message[]>(url);
}


function seen(conversationId: string, messageId: string, everyone: 0 | 1): Promise<Response<Message[]>> {
    const url = `user/chat/conversations/${conversationId}/messages/${messageId}/seen`;
    return axios.post<Message[]>(url, { everyone });
}

function message(conversationId: string, formData: object): Promise<Response<Message>> {
    const url = `user/chat/conversations/${conversationId}/messages`;
    return axios.post<Message>(url, formData);
}

function reply(conversationId: string, messageId: string, type: string, body: string): Promise<Response<Message>> {
    const url = `user/chat/conversations/${conversationId}/messages/${messageId}/reply`;
    const d = { type, body }
    return axios.post<Message>(url, d);
}
function operateOnConversation(conversationId: string, operation: ConversationOperationsEnum, body?: object): Promise<Response<Message>> {
    const url = `user/chat/conversations/${conversationId}/${operation}`;
    return axios.post<Message>(url, body);
}

// user/chat/streams?filter[date]=2022-05-30,day
// api/user/chat/streams?filter[date]=2022-05-30,month
function streamsFilter(date: string, type: 'day' | 'month') {
    const url = `user/chat/streams?filter[date]=${date},${type}`;
    return axios.get<Message>(url);
}

function deleteConverstation(conversationId): Promise<Response<void>> {
    const url = `user/chat/conversations/${conversationId}?_method=DELETE`;
    return axios.post<void>(url);
}


function getConversationRecordings(conversationId, page): Promise<Response<Recording[]>> {
    const url = `user/chat/conversations/${conversationId}/recordings?page=${page}`;
    return axios.get<Recording[]>(url);
}
