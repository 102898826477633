import { text } from '@fortawesome/fontawesome-svg-core';
import axios from 'axios';
import _ from 'lodash';
import { Store } from 'vuex';
import { Errors } from '../_data/enums/errors.enum';

export default function setup(store: Store<unknown>) {

    let hearderErrorMsg;

    axios.interceptors.request.use((config) => {

        // const token = JSON.parse(localStorage.getItem('user')).token;
        const token = store.getters['account/token'];
        hearderErrorMsg = config.headers.errorMsg;
        config.url = process.env.VUE_APP_BASE_URL + config.url;
        if (config && config.headers && token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    }, function (error) {
        return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
        return response.data;
    }, function (error) {
        const d = error.response?.data;
        check401(d.code, store);
        showErrors(d.errors, d.message, hearderErrorMsg, store);
        return Promise.reject(error);
    });

}


function check401(code, store) {
    if (code == 401) {
        setTimeout(() => {
            store.dispatch('account/logout');
        }, 1000);
    }
}

function showErrors(errors, msg, headerMsg, store: Store<unknown>) {
    if (headerMsg === null) {
        return;
    } else if (headerMsg) {
        msg = Errors[headerMsg];
    }

    if (_.isEmpty(errors)) {
        store.dispatch('alert/error', msg);
    } else {
        const text = createErrorDivs(errors);
        store.dispatch('alert/error', text);
    }

}



function createErrorDivs(errors): string {
    let text = '';
    const es = _.isObject(errors) ? _.values(errors) : errors;
    es.forEach(e => {
        text += `<div>${e}</div>`;
    }
    );
    return text;
}
