import { InjectionKey } from 'vue';
import Vuex, { Store } from 'vuex';
import { UserData } from '../_data';
import { account } from './account.module';
import { alert, AlertState } from './alert.module';
import { chat } from './chat.module';
import { contacts } from './contacts.module';
import { imageViewer } from './imageViewer.module';
import { streams } from './streams.module';


export interface State {
    account: UserData,
    alert: AlertState,
}


export const key: InjectionKey<Store<State>> = Symbol()

export const store = new Vuex.Store<State>({
    modules: {
        alert,
        account,
        contacts,
        streams,
        chat,
        imageViewer,
    }
});