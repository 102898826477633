import { UserData } from '@/core';
import axios, { AxiosRequestConfig } from 'axios';
import { LoginData, Plan, Response, SocialLoginData, User } from '../_data';
import { router } from '../_helpers/router';
export const userService = {
    login,
    socialLogin,
    facebookInitLogin,
    logout,
    register,
    updateProfile,
    forgotPassword,
    checkOtp,
    resetPassword,
    getVerificationCode,
    verifyPhone,
    deactivate,
    plans,
    homePlans
};

const platform = {
    name: "dummy",
    os: "dummy",
    model: "dummy",
    token: "dummy",
}
function login(username: string, password: string) {
    const url = "user/auth/login";
    const data: LoginData = {
        username: username,
        password: password,
        platform: platform
    };

    const config: AxiosRequestConfig = { headers: { 'errorMsg': 'wrong-login' } };
    return axios
        .post<UserData>(url, data, config)
        .then(res => {
            const user = res.data;
            if (user.token) {
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        })
}


function socialLogin(provider: string, id: string, social_type: string) {
    const url = "user/auth/social/login";
    const config: AxiosRequestConfig = { headers: { 'errorMsg': null } };
    const data: SocialLoginData = {
        provider: provider,
        token: id,
        platform: platform,
        social_type: social_type,
    };

    return axios
        .post<UserData>(url, data, config)
        .then(res => {
            const user = res.data;
            if (user.token) {
                localStorage.setItem('user', JSON.stringify(user));
            }
            return res;
        }).catch(
            error => {
                router.push({
                    path: 'register',
                    query: { provider, id }
                });
                return Promise.reject(error);
            }
        )
}



function facebookInitLogin(id: string) {
    const url = "user/auth/social/login";
    const data: SocialLoginData = {
        provider: 'facebook',
        token: id,
        platform: platform
    };

    return axios
        .post<UserData>(url, data)
        .then(res => {
            const user = res.data;
            if (user.token) {
                localStorage.setItem('user', JSON.stringify(user));
            } else {
                localStorage.clear();
            }
            return res;
        }).catch(
            error => {
                return error;
            }
        )
}


function updateProfile(data: User) {
    const url = "user/profile?_method=PATCH";

    return axios
        .post<User>(url, data)
        .then(res => {
            if (res.code === 2000) {
                const userData = JSON.parse(localStorage.getItem('user') as string);
                userData.user = res.data;
                localStorage.setItem('user', JSON.stringify(userData));
                return userData;
            }
        });

}

function register(data: User) {
    const url = "user/auth/register";

    const d = {
        ...data,
        platform: platform
    }
    return axios
        .post<User>(url, d)
        .then(res => {
            if (res.success) {
                const user = res.data;
                if (user.token) {
                    localStorage.setItem('user', JSON.stringify(user));
                }
                return user;
            }
        });
}

function forgotPassword(username: string) {
    const url = 'user/auth/password/request';
    return axios
        .post<void>(url, { username: username })
        .then(res => {
            if (res.code === 2000) {
                return res;
            }
        });
}
function checkOtp(username: string, otp: string) {
    const url = 'user/auth/password/otp/check';
    return axios
        .post<void>(url, { username, otp })
        .then(res => {
            if (res.code === 2000) {
                return res;
            }
        });
}
function resetPassword(resetData: object) {
    const url = 'user/auth/password/reset';
    resetData = { ...resetData, platform };
    return axios
        .post<UserData>(url, resetData)
        .then(res => {
            const user = res.data;
            if (user.token) {
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        });
}
function getVerificationCode() {
    const url = 'user/auth/mobile/verify/resend';
    return axios
        .post<UserData>(url);
}
function verifyPhone(code: string) {
    const url = 'user/auth/mobile/verify';
    return axios
        .post<UserData>(url, { verification_code: code });
}



function logout() {
    localStorage.removeItem('user');
    location.reload();
}
function deactivate(password: string): Promise<Response<void>> {
    const url = 'user/account/deactivate';
    const password_confirmation = password;
    return axios
        .post<void>(url, { password, password_confirmation });
}

function plans(): Promise<Response<Plan[]>> {
    const url = "user/plans";
    return axios.get<Plan[]>(url);
}
function homePlans(): Promise<Response<Plan[]>> {
    const url = "user/home/plans";
    return axios.get<Plan[]>(url);
}
