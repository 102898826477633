
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'EyuAlertHandler',
  computed: {
    ...mapGetters('alert', ['alert']),
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
    }),
  },
  watch: {
    $route() {
      // clear alert on location change
      this.clearAlert();
    },
  },
};
