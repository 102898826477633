import _ from 'lodash';
import { Commit, Dispatch } from 'vuex';
import { Plan, Response, User, UserData } from '../_data';
import { CommitDispatch } from '../_data/types/commit-dispatch';
import { router } from '../_helpers/router';
import { userService } from '../_services';

const userData: UserData = JSON.parse(localStorage.getItem('user') as string);
const state: UserData = userData
    ? { user: userData.user, token: userData.token, setting: userData.setting }
    : { user: null, setting: null, token: null };

const getters = {
    user: (state: UserData) => {
        return _.cloneDeep(state.user)
    },
    token: (state: UserData) => {
        return state.token
    },
    loggedIn: (state: UserData) => {
        return !!state.user;
    },
    setting: (state: UserData) => {
        return _.cloneDeep(state.setting)
    },
}
const actions = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    login({ dispatch, commit }: CommitDispatch, { username, password }: {
        username: string;
        password: string;
    }) {


        userService.login(username, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push('/');
                    setTimeout(() => { dispatch('alert/success', 'Welcome back ' + user.user.first_name, { root: true }); }, 200)
                },
                error => {
                    commit('loginFailure', error);
                }
            );
    },
    socialLogin({ dispatch, commit }: CommitDispatch, { provider, id, social_type }: {
        provider: string;
        id: string;
        social_type: string;
    }) {
        userService.socialLogin(provider, id, social_type)
            .then(
                (res: Response<UserData>) => {
                    if (res.success) {
                        commit('loginSuccess', res.data);
                        router.push('/');
                        setTimeout(() => { dispatch('alert/success', 'Welcome back ' + res.data.user.first_name, { root: true }); }, 200)
                    }
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },
    updateProfile({ dispatch, commit }: CommitDispatch, user: User) {
        userService.updateProfile(user)
            .then(
                user => {
                    commit('loginSuccess', user);
                    dispatch('alert/success', 'Update successful', { root: true });
                },
            );
    },

    logout({ commit }: { commit: Commit; }) {
        userService.logout();
        commit('logout');
    },

    forgotPassword({ dispatch }: { dispatch: Dispatch; }, username: string) {
        userService.forgotPassword(username)
            .then(
                () => {
                    dispatch('alert/success', 'A code was sent to you', { root: true });
                },
            );
    },
    checkOtp({ dispatch }: { dispatch: Dispatch; }, { username, otp }: { username: string, otp: string }) {
        userService.checkOtp(username, otp)
            .then(
                () => {
                    dispatch('alert/success', 'Enter new password', { root: true });
                },
            );
    },
    resetPassword({ dispatch, commit }: CommitDispatch, resetData: object) {
        userService.resetPassword(resetData)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push('/');
                    dispatch('alert/success', 'Password was change, welcome back', { root: true });
                },
            );
    },
    deactivate({ dispatch, commit }: CommitDispatch, password: string): void {
        userService.deactivate(password)
            .then(
                () => {
                    userService.logout();
                    commit('logout');
                },
            );
    },

    register({ dispatch, commit }: CommitDispatch, user: User) {
        userService.register(user)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push('/');
                },
            );
    },

    getVerificationCode({ dispatch }: { dispatch: Dispatch; }) {
        userService.getVerificationCode()
            .then(
                () => {
                    dispatch('alert/success', 'A code was sent to you', { root: true });
                },
            );
    },

    verifyPhone({ dispatch, commit }: CommitDispatch, code: string) {
        userService.verifyPhone(code)
            .then(
                () => {
                    dispatch('alert/success', 'Phone was verified', { root: true });
                    userData.user.mobile_verified = true;
                    localStorage.setItem('user', JSON.stringify(userData));
                    commit('loginSuccess', userData);
                },
            );
    },
    plans(): Promise<Plan[]> {
        return userService.plans()
            .then(
                res => {
                    return res.data;
                }
            );
    },
    homePlans(): Promise<Plan[]> {
        return userService.homePlans()
            .then(
                res => {
                    return res.data;
                }
            );
    },
};

const mutations = {

    loginSuccess(state: UserData, userData: UserData) {
        state.user = userData.user;
        state.token = userData.token;
        state.setting = userData.setting;
    },
    updateUserData(user: User) {
        state.user = user;
    },
    loginFailure(state: UserData) {
        state.user = null;
        state.token = null;
        state.setting = null;
    },
    logout(state: UserData) {
        state.user = null;
        state.token = null;
        state.setting = null;
    },
    // registerRequest(state: { status: { registering: boolean; }; }) {
    //     state.status = { registering: true };
    // },
    // registerSuccess(state: UserData) {
    //     state.user = {};
    // },
    // registerFailure(state: { status: any; }) {
    //     state.status = {};
    // }
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};