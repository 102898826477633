import { Dispatch } from 'vuex';
import { Stream, StreamCriteria } from '../_data';
import { streamsService } from '../_services';


const actions = {
    createStream({ dispatch }: { dispatch: Dispatch; }, data: StreamCriteria): Promise<Stream> {
        return streamsService.createStream(data).then(
            res => {
                dispatch('alert/success', 'Stream started', { root: true });
                return res.data;
            }
        );
    },
    showStream({ dispatch }: { dispatch: Dispatch; }, streamId: string): Promise<Stream> {
        return streamsService.showStream(streamId).then(
            res => {
                return res.data;
            }
        );
    },

    getStreamRecords({ dispatch }: { dispatch: Dispatch; }, streamId: string): Promise<Stream> {
        return streamsService.getStreamRecords(streamId).then(
            res => {
                return res.data;
            }
        );
    },
    joinStream(
        { dispatch }: { dispatch: Dispatch; },
        { streamId, passcode }: { streamId: string, passcode: string }
    ): Promise<Stream> {      
        return streamsService.joinStream(streamId, passcode).then(
            res => {
                dispatch('alert/success', 'Stream joined', { root: true });
                return res.data;
            }
        );
    },
    joinStreamVisitor(
        { dispatch }: { dispatch: Dispatch; },
        { streamId, passcode }: { streamId: string, passcode: string }
    ): Promise<Stream> {
        return streamsService.joinStreamVisitor(streamId, passcode).then(
            res => {
                dispatch('alert/success', 'Stream joined', { root: true });
                return res.data;
            }
        );
    },

    updateStream(
        { dispatch }: { dispatch: Dispatch; },
        { streamId, data }: { streamId: string, data: StreamCriteria }
    ): Promise<Stream> {
        return streamsService.updateStream(streamId, data).then(
            res => {
                dispatch('alert/success', 'Stream joined', { root: true });
                return res.data;
            }
        );
    },
    deleteStream({ dispatch }: { dispatch: Dispatch; }, streamId: string): Promise<void> {
        return streamsService.deleteStream(streamId).then(
            () => {
                dispatch('alert/success', 'Stream joined', { root: true });
                return null;
            }
        );
    }
}



export const streams = {
    namespaced: true,
    actions,
};