
import { mapGetters } from "vuex";

export default {
    name: 'EyuImageViewer',
    computed: {
        ...mapGetters('imageViewer', ['image']),
    },
    watch: {
        image(value) {
            console.log(value);
        }
    },
    methods: {
        lg() {
            console.log(this.image);

        }
    },
}
