import _ from 'lodash';
import { Commit } from 'vuex';

export interface ImageState {
    name: string;
    url: string;
}

const state: ImageState = {
    name: 'fff',
    url: null,
};

const getters = {
    image: (state: ImageState) => {
        return state
    },
}

const actions = {
    expandImage({ commit }: { commit: Commit; }, img: ImageState) {
        commit('expandImage', img);
    },
};

const mutations = {
    expandImage(state: ImageState, img: ImageState) {
        state.name = img.name;
        state.url = img.url;
    },
};

export const imageViewer = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
