import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import axios from 'axios';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import VCalendar from 'v-calendar';
import { createApp } from 'vue';
import VueAxios from 'vue-axios';
import GAuth from 'vue3-google-oauth2';
import App from './App.vue';
import { router } from './core/_helpers';
import { initFacebookSdk } from './core/_helpers/fb.js';
import interceptorsSetup from './core/_helpers/JwtInterceptor';
import { key, store } from './core/_store';
library.add(fas, far, fab);
dom.watch();

interceptorsSetup(store);




initFacebookSdk();


const app = createApp(App)

const gAuthOptions = { clientId: process.env.VUE_APP_GOOGLE_APP_ID, scope: 'email', prompt: 'consent', fetch_basic_profile: true }
app.use(GAuth, gAuthOptions)
app.use(store, key);
app.use(router);
app.use(VueAxios, axios);
app.use(VCalendar, {});
app.component("font-awesome-icon", FontAwesomeIcon);
app.component('vue-datepicker', Datepicker);
app.config.compilerOptions.isCustomElement = (tag) => {
    return tag.startsWith('eyu-');
};
app.mount('#app');