import Pusher from 'pusher-js';

export class PusherStarter {
    constructor(token) {
        return new Pusher(process.env.VUE_APP_PUSHER_KEY, {
            cluster: 'eu',
            authEndpoint: process.env.VUE_APP_PUSHER_AUTH_URL,
            auth: {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            },
        });
    }
}