import { Dispatch } from 'vuex';
import { ContactData, ContactOperationsEnum, Response } from '../_data';
import { contactsService } from '../_services';

const actions = {
    getContacts({ dispatch }: { dispatch: Dispatch; }, { page, blocked }: { page: number, blocked: boolean }): Promise<Response<ContactData[]>> {
        return contactsService.getContacts(page, blocked)
            .then(
                res => {
                    return res;
                }
            );
    },

    findContact({ dispatch }: { dispatch: Dispatch; }, username: string): Promise<ContactData> {
        return contactsService.findContact(username)
            .then(
                res => {
                    return res.data;
                }
            );
    },
    operateOnContact({ dispatch }: { dispatch: Dispatch; }, { userId, operation, obj }: { userId: string, operation: ContactOperationsEnum, obj: object }) {
        return contactsService.operateOnContact(userId, operation, obj)
            .then(
                res => {
                    dispatch('alert/success', operation + ' successful', { root: true });
                    return res.data;
                }
            );
    }

}

export const contacts = {
    namespaced: true,
    actions,
};