import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_eyu_alert_handler = _resolveComponent("eyu-alert-handler")!
  const _component_eyu_image_viewer = _resolveComponent("eyu-image-viewer")!
  const _component_eyu_verfication = _resolveComponent("eyu-verfication")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_eyu_alert_handler),
    _createVNode(_component_eyu_image_viewer),
    _createVNode(_component_eyu_verfication),
    _createVNode(_component_router_view)
  ], 64))
}