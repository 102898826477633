import axios, { AxiosRequestConfig } from 'axios';
import { ContactData, ContactOperationsEnum, Response } from '../_data';

export const contactsService = {
    getContacts,
    findContact,
    operateOnContact,
}

function getContacts(page, blocked): Promise<Response<ContactData[]>> {
    let url = `user/contacts?page=${page}`;
    if (blocked) {
        url += '&filter[blocked]=true'
    }
    return axios
        .get<ContactData[]>(url);
}

function findContact(username: string): Promise<Response<ContactData>> {
    const url = "user/contacts/find";
    const config: AxiosRequestConfig = { headers: { 'errorMsg': 'no-user-found' } };
    return axios
        .post<ContactData>(url, { username }, config);
}

function operateOnContact<T>(userId: string, operation: ContactOperationsEnum, obj?: object): Promise<Response<T>> {
    const url = `user/contacts/${userId}/${operation}`;
    return axios
        .post<T>(url, obj);
}


