import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: 'home' */ '@/views/Home.vue'),
    meta: {
      requiresAuth: false,
      title: 'Éyu'
    }
  },
  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: 'download' */ '@/views/DownloadApps.vue'),
    meta: {
      title: 'Éyu - Download'
    }
  },
  {
    path: '/terms&conditions',
    name: 'eyuTerms',
    component: () => import(/* webpackChunkName: 'terms' */ '@/views/eyu/Terms.vue'),
    meta: {
      title: 'Éyu - Terms & Conditions'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: 'Login' */ '@/views/auth/Login.vue'),
    meta: {
      requiresAuth: false,
      title: 'Éyu - Login'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: 'register' */ '@/views/auth/Register.vue'),
    meta: {
      requiresAuth: false,
      title: 'Éyu - Register'
    }
  },
  {
    path: '/forgotPassord',
    name: 'forgotPassord',
    component: () => import(/* webpackChunkName: 'forgotPassord' */ '@/views/auth/ForgotPassword.vue'),
    meta: {
      requiresAuth: false,
      title: 'Éyu - Forgot Password'
    }
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: 'dashboard' */ '@/views/user/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      title: 'Éyu'
    }
  },
  {
    path: '/join',
    name: 'joinStream',
    component: () => import(/* webpackChunkName: 'joinStream' */ '@/views/user/stream/JoinStream.vue'),
    meta: {
      title: 'Éyu - Join Stream'
    }
  },
  {
    path: '/host',
    name: 'hostStream',
    component: () => import(/* webpackChunkName: 'hostStream' */ '@/views/user/stream/HostStream.vue'),
    meta: {
      requiresAuth: true,
      title: 'Éyu - Host stream'
    }
  },
  {
    path: '/streams/:id',
    name: 'streams',
    component: () => import(/* webpackChunkName: 'stream' */ '@/views/user/stream/Stream.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Éyu - Stream'
    }
  },
  {
    path: '/chat/:id',
    name: 'conversation',
    component: () => import(/* webpackChunkName: 'conversations' */ '@/views/user/conversations/Conversations.vue'),
    meta: {
      requiresAuth: true,
      title: 'Éyu - Chat'
    }
  },
  {
    path: '/chat',
    name: 'conversations',
    component: () => import(/* webpackChunkName: 'conversations' */ '@/views/user/conversations/Conversations.vue'),
    meta: {
      requiresAuth: true,
      title: 'Éyu - Chat'
    }
  },
  {
    path: '/meetings',
    name: 'meetings',
    component: () => import(/* webpackChunkName: 'meetings' */ '@/views/user/meetings/Meetings.vue'),
    meta: {
      requiresAuth: true,
      title: 'Éyu - Meetings'
    }
  },

  {
    path: '/contacts',
    name: 'contacts',
    component: () => import(/* webpackChunkName: 'contacts' */ '@/views/user/contacts/Contacts.vue'),
    meta: {
      requiresAuth: true,
      title: 'Éyu - Contacts'
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: 'settings' */ '@/views/user/settings/UserSettings.vue'),
    meta: {
      requiresAuth: true,
      title: 'Éyu - Settings'
    }
  },
  {
    path: '/plans',
    name: 'plans',
    component: () => import(/* webpackChunkName: 'plans' */ '@/views/user/settings/UserPlans.vue'),
    meta: {
      requiresAuth: true,
      title: 'Éyu - Plans'
    }
  },
  {
    path: '/plans/success',
    name: 'paymentSuccess',
    component: () => import(/* webpackChunkName: 'paymentSuccess' */ '@/views/user/settings/PaymentSuccess.vue'),
    meta: {
      requiresAuth: true,
      title: 'Éyu - Payment Success'
    }
  },


  // otherwise redirect to home
  // {
  //   path: "/:catchAll(.*)",
  //   name: "NotFound",

  // }
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to) => {
  const loggedIn = localStorage.getItem('user');
  document.title = to.meta.title as string;
  if (to.meta.requiresAuth && !loggedIn) {
    return { name: 'login' };
  } else if (to.meta.requiresAuth === false && loggedIn) {
    return { name: 'dashboard' };
  }
  return;
})


